<template>
    <div>
      <b-container>
        <b-row>
          <b-col>
            <div id="my-upload" style="display: flex; justify-content: center;">
            <vue-upload-multiple-image
              :key="idupload"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :accept='"image/jpeg,image/png,image/jpg"'
              :data-images="form.images"
              :dragText="component.picOptions.dragText"
              :browseText="component.picOptions.browseText"
              :primaryText="component.picOptions.primaryText"
              :markIsPrimaryText="component.picOptions.markIsPrimaryText"
              :popupText="component.picOptions.popupText"
              :dropText="component.picOptions.dropText"
              :showPrimary="false"
              :multiple="false"
            ></vue-upload-multiple-image>
          </div>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Nome:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  v-model="form.name"
                                  placeholder="Digite o nome">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Login:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  v-mask="'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'"
                                  maxlength="50"
                                  v-model="form.login"
                                  placeholder="Digite o login">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Email:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="email"
                                  maxlength="100"
                                  v-model="form.email"
                                  placeholder="Digite o e-mail">
                      </b-form-input>
                  </b-input-group>
              </b-row>

              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Data limite para acessar o sistema:
                      </b-input-group-prepend>
                      <HotelDatePicker
                      class="item-date-picker-revenue"
                      format="DD-MM-YYYY"
                      :disabledDate="component.datepicker.disabledDate"
                      :value-type='component.datepicker.valueType'
                      v-model="form.loginValidUntil"
                      type="date"
                      confirm
                      placeholder="Selecione o período"
                    ></HotelDatePicker>
                  </b-input-group>
              </b-row>


              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-select v-model="form.phoneType" size="sm">
                        <option value="(##) #####-####">Celular</option>
                      </b-form-select>
                      <b-form-input id="name"
                                  type="text"
                                  name="phone"
                                  maxlength="25"
                                  v-mask="form.phoneType"
                                  v-model="form.phone"
                                  placeholder="Informe o número com DDD">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-select v-model="form.documentType" size="sm">
                        <option value="###.###.###-##">CPF</option>
                        <option value="##.###.###/####-##">CNPJ</option>
                        <option value="">Outros</option>
                      </b-form-select>
                      <b-form-input id="name"
                                  type="text"
                                  name="document"
                                  maxlength="25"
                                  v-mask="form.documentType"
                                  v-model="form.document"
                                  placeholder="Digite o documento">
                      </b-form-input>
                  </b-input-group>
              </b-row>
           
           <b-row class="mb-3">
                   <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                  <span>Tipo de comissão</span>
                </b-input-group-prepend>
                
                   <b-form-select v-model="form.tipo_comissao" size="sm">
                        <option  value="">Sem comissão</option>
                        <option value="V">POR VENDA</option>
                        <option value="R">POR RECEITA</option>
                      </b-form-select>
                  
                  
                
                  </b-input-group>
              </b-row>
           

          
              <b-row>
              <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                  <span>Comissão</span>
                </b-input-group-prepend>
                  <b-form-input
                    ref="comissao"
                    id="comissao"
                    type="text"
                    name="comissao"
                    maxlength="8"
                    v-money="component.money"
                    v-model.lazy="form.comissao"
                  ></b-form-input>
             
            
                    <b-input-group-prepend is-text class="firstLabel">
                      Início Vigẽncia:
                    </b-input-group-prepend>
                    <b-form-input
                      id="localizacao"
                      type="text"
                      name="localizacao"
                      v-model="form.inicio_vigencia"
                    v-mask="'##/##/####'"
                      placeholder="dd/mm/YYYY"
                    >
                    </b-form-input>
             
               
                  
                </b-input-group>
                <span id="span_dtpin" class="text-muted small ml-3 pt-1"></span>
              </b-row>
          

              <b-row class="mb-3">
                <div v-if="!isAdd" style="margin-right: 15px;" class="alert alert-primary">Deseja alterar a senha desse usuário? Digite a senha nova no campo e salve.</div>
                <password labelHide="Esconder senha" labelShow="Mostrar senha" v-model="form.pass" placeholder="Digite a senha" :secureLength="7" :toggle="true" />
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-checkbox id="active"
                                      v-model="form.active"
                                      value="1"
                                      unchecked-value="not_accepted">
                      <span v-if="form.active == 1">Ativo</span>
                      <span v-else>Inativo</span>
                      </b-form-checkbox>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-button type="button" variant="success" size="sm" @click="save">
                  <v-wait for="inprocess">
                      <template slot="waiting">
                          Carregando...
                      </template>
                  </v-wait>
                  <v-wait for="inprocessSave">
                      <template slot="waiting">
                          Salvando...
                      </template>
                  </v-wait>
                  <span v-if="!processing">Salvar</span>
                </b-button>
              </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import VueMask from 'v-mask';
import config from "@/config";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Password from 'vue-password-strength-meter';
import { func } from "@/functions";
import { userService } from '../../components/common/services/user';
import { VMoney } from "v-money";
import HotelDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
Vue.use(VueHead);
Vue.use(VueMask);

export default {
  mixins: [func],
  props: ['id'],
  components: { Password, VueUploadMultipleImage, HotelDatePicker },
  name: 'user-add',
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: `Usuário - ${this.typeOf}`,
      }
    },
  },
  created() {
    if (!this.isAdd) {
      this.get();
    }
  },
   directives: {
    money: VMoney
  },
  computed: {
    mayIsee() {
      return this.mayI('user-add', 'user-viewer');
    },
    typeOf() {
      return this.isAdd ? "Adicionar" : "Alterar";
    },
    isAdd() {
      return this.id == '' || this.id == null || this.id == undefined;
    }
  },
  methods: {
    async uploadImageSuccess(formData) {
      const file = formData.get('file');
      const self = this;
      const reader = new FileReader();
      this.processing = true;
      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();
      reader.onloadend = async function () {
        const base64String = reader.result;
        self.form.imgbase64 = base64String;
        self.processing = false;
        self.hideWaitAboveAll();
        self.$wait.end("inprocessSave");
      };

      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    },
    beforeRemove(index, done, fileList) {
      this.form.imgbase64 = "remove";
      done();
    },
    async editImage(formData, index, fileList) {
      await this.uploadImageSuccess(formData);
    },
    validate() {
      if (this.form.pass != '' && this.form.pass.length<7) {
        this.toastError("Preencha a senha corretamente.");
        return false;
      }
      return true;
    },
    save() {
      if (this.processing) return;

      if (!this.validate()) return;

      this.form.changedpass = this.form.pass != '' && this.form.pass >= 7;

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();
      const loginValidUntil = this.form.loginValidUntil ?  moment(this.form.loginValidUntil).format("YYYY-MM-DD") : null;
      userService.save(this.getLoggedId(), this.isAdd ? '' : this.id, this.form.name, this.form.login, this.form.email, this.form.document, this.form.active, this.form.pass, this.form.changedpass,this.form.tipo_comissao,this.form.comissao,this.form.inicio_vigencia, this.form.imgbase64, this.form.phone, loginValidUntil).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");

          if (this.validateJSON(response))
          {
             if (response.success) {
               this.toastSuccess("Salvo com sucesso");
               if (this.getLoggedId() === this.id) {
                if (response.image) {
                  this.ls_add('image', response.image);
                }
               }
               this.$router.push(`/user/list`);
             }
             else {
               this.toastError(response.msg);
             }
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");
          this.toastError("Falha na execução.");
        }
      );      
    },
    get() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      userService.get(this.id).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response))
          {
              this.form.loaded = true;
              this.form.name = response.name;
              this.form.document = response.document;
              this.form.login = response.login;
              this.form.email = response.email;
              this.form.active = response.active;
              this.form.comissao = (response.comissao*100)/100;
              this.$refs.comissao.$el.value = ((response.comissao*100)/100).toFixed(2);
              this.form.phone = response.phone;
              if (response.login_valid_until) {
                this.form.loginValidUntil = response.login_valid_until.date;
              }
              if (response.image) {
                this.form.images = [
                  {
                    path: response.image,
                    default: 1,
                    highlight: 1,
                    caption: 'caption to display. receive', // Optional
                  }
                ];
              }

           
              this.form.tipo_comissao = response.tipo_comissao;
              this.form.inicio_vigencia = response.inicio_vigencia;
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    } 
  },
 
  data () {
    return {
        processing: false,
        loading: false,
        idupload: 0,
      component: {
        money: {
          decimal: ".",
          thousands: "",
          //prefix: '',
          //suffix: ' ',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        picOptions: {
          dragText: "Arraste a imagem com no máximo 1MB no formato JPG/PNG, com dimensões de 100x100px",
          browseText: "Selecione",
          primaryText: "Padrão",
          markIsPrimaryText: "Definir como padrão",
          popupText: "Esta imagem será exibida como padrão",
          dropText: "Solte aqui"
        },
        datepicker: {
          disabledDate: (date) => {
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return date < today;
          },
          valueType: 'YYYY-MM-DD',
        }
      },
        form: {
          imgbase64: "",
          loginValidUntil: "",
          image: "",
          saveimage: false,
          documentType:"###.###.###-##",
          phoneType:"(##) #####-####",
          loaded: false,
          pass: '',
          changedpass: false,
          id: '',
          name: '',
          phone: '',
          document: '',
          login: '',
          email: '',
          active: 1,
          tipo_comissao: '',
          comnissao: '',
          inicio_vigencia: '',

        }
    }
  }
}
</script>

<style lang='scss'>
.Password {
    max-width: 400px;
    margin: 0 0 !important;
}
#my-upload {
  margin: 10px;
  .text-center {
    .drag-text {
      font-size: 11px;
      color: #424242;
    }
  }
  .image-center {
    padding: 10px;
  }
}
</style>
